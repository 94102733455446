.main_nav{
   background-color: rgb(75, 72, 72);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}
.container{
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    width: 95%;
}
.container img{
    padding: 20px;
    padding-left: 0px; 
    width: 15vw;
}
.nav_btn{
    border: none;
    outline: none;
    cursor: pointer;
    color: rgb(107, 7, 7);
    background-color: #f5e0e0;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 700;
    padding: 11px 14px;
}
.nav_btn a{
    color: black;
}
.nav_btn a:hover{
    color:red;
}
/* Responsiveness starts here */
@media screen and (max-width:1025px) {
    .container img{
        width: 25vw;
        padding: 20px;
    }
    .container{
        display: flex;
        justify-content: space-between;
        color: white;
        align-items: center;
        width: 100%;
    }
    .nav_btn{
        border: none;
        outline: none;
        cursor: pointer;
        color: rgb(107, 7, 7);
        background-color: #f5e0e0;
        border-radius: 25px;
        font-size: 12px;
        font-weight: 700;
        padding: 11px 14px;
        letter-spacing: .5px;
        align-items: center;
        height: 7vh;
        display: flex; 
        justify-content: center;
    }
}
/* 2nd media quiery *********************************************/
@media screen and (max-width:766px) {
    .container img{
        width: 30vw;
        padding: 20px;
    }
    .nav_btn{
        border: none;
        outline: none;
        cursor: pointer;
        color: rgb(107, 7, 7);
        background-color: #f5e0e0;
        border-radius: 25px;
        font-size: 12px;
        font-weight: 700;
        padding: 11px 14px;
        letter-spacing: .5px;
        align-items: center;
        height: 6vh;
        display: flex; 
        margin-right: 10px;
        justify-content: center;
    }
}

@media screen and (max-width:500px) {
    .container img{
        width: 50vw;
        padding: 20px;
    }
    .nav_btn{
        border: none;
        outline: none;
        cursor: pointer;
        color: rgb(107, 7, 7);
        background-color: #f5e0e0;
        border-radius: 25px;
        font-size: 9px;
        font-weight: 700;
        padding: 11px 14px;
        letter-spacing: .5px;
        align-items: center;
        height: 5vh;
        display: flex; 
        justify-content: center;
    }
}