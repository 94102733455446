.container{
    margin: 50px auto;
    width: 31%;
}
.head_text{
   text-align: center;
    font-weight: 900;
    letter-spacing: .5px;
    padding: 10px;
    margin-top: 20px;
}
.head_text sub {
  color: red;
  font-size: 40px;
  font-weight:500 ;
}
.head_text span{
    color: red;
}
.division_head{
   display: flex;
   flex-direction: column;
   height: 100%; 
   box-shadow: 0px 2px 10px #80808073;
    border-radius: 18px;
}
.availability_text{
    background-color: rgb(172, 32, 32);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px 10px;
    color: white;
}
.availability_text p{
    font-size: 16px;
    height: 7vh;
    display: flex;
    align-items: center;
    line-height: 1.5;
}
.availability_text span{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 27px;
    font-weight: 700;
    padding-bottom: 10px;
}
.main_list{
    height: 100%;
}
.division_list{
    font-size: 16px;
    padding: 13px;
    margin-top: 10px;
    display: flex;
}
.division_list p{
    margin-left: 10px;
}
.division_list img{
    width: 17px;
    margin-right: 0px;
    object-fit: contain;
}
/* Body section start ************/

.division_body{
    border-top: 3px solid rgb(214, 211, 211);
    display: flex;
    padding: 16px 15px;
    font-size: 16px;
    line-height: 1.5;
}

/* Foot Section start */

.division_foot{
    padding: 8px;
    color: white;
    background-color: rgb(172, 32, 32);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-top: auto;
}
.division_foot h4{
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 10px;
}
.division_foot span{
    font-size: 20px;
    font-weight: 100;
}
.division_foot a{
        border: none;
        outline: none;
        cursor: pointer;
        color: rgb(107, 7, 7);
        background-color: #f5e0e0;
        border-radius: 5px;
        padding: 4px 8px;
        font-size: 17px;
        font-weight: 700;
        letter-spacing: .5px;
        align-items: center;
        width: fit-content;
        margin: 10px auto;
        display: block;
}
.division_foot a:hover{
    color:red;
}
/* ****************************************************************** 1st Media quiery*******************************************  */


@media screen and (max-width:1024px) {
    .container{
        width: 32%;
        margin: 40px auto;
        margin-bottom: 0px;
    }
    .division_head{
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 20px;
    }
    .availability_text{
        background-color: rgb(172, 32, 32);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 9px 9px;
        color: white;
    }
    .availability_text p{
        height: 7vh;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 1.5;
    }
    .availability_text span{
        text-align: center;
        font-size: 23px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }
    .main_list{
        height: 100%;
    }
    .division_list{
        font-size: 13px;
        margin-top: 5px;
        display: flex;
    }
    .division_list img{
        width: 12px;
        margin-right: 10px;
    }
    
    /* Body section start ************/
    .division_body{
        border-top: 2px solid rgb(214, 211, 211);
        display: flex;
        padding: 16px 0px;
        line-height: 1.5;
        font-size: 12px;
    }
    
    /* Foot Section start */
    
    .division_foot{
        padding: 10px;
        color: white;
        background-color: rgb(172, 32, 32);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-top: auto;
    }
    .division_foot h4{
        font-size: 22px;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .division_foot span{
        font-size: 17px;
        font-weight: 100;
    }
    .division_foot a{
            border: none;
            outline: none;
            cursor: pointer;
            color: rgb(107, 7, 7);
            background-color: #f5e0e0;
            border-radius: 5px;
            font-size: 13px;
            font-weight: 900;
            margin: 0 auto;
    }
}

/* ****************************************************************** 2nd Media quiery*******************************************  */


@media screen and (max-width:768px) {
    .container{
        width: 32%;
        margin: 0 auto;
    }
    .head_text{
        font-size: 15px;
        font-weight: 900;
    }
    .head_text sub {
      color: red;
      font-size: 35px;
      font-weight:500 ;
    }
    .head_text span{
        color: red;
    }
    .division_head{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .availability_text{
        background-color: rgb(172, 32, 32);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 16px 9px;
        color: white;
    }
    .availability_text p{
        height: 7vh;
        display: flex;
        align-items: center;
        font-size: 12px;
    }
    .availability_text span{
        text-align: center;
        font-size: 22px;
        display: flex;
        justify-content: center;
        font-weight: 600;
        padding-bottom: 10px;
    }
    .main_list{
        height: 100%;
    }
    .division_list{
        font-size: 12px;
        padding: 5px;
        margin-top: 15px;
        display: flex;
    }
    .division_list img{
        width: 14px;
        margin-right: 10px;
    }
    /* Body section start ************/
    .division_body{
        border-top: 3px solid rgb(214, 211, 211);
        display: flex;
        padding: 13px 0px;
        align-items: center;
        font-size: 11px;
        line-height: 1.4;
    }
    
    /* Foot Section start */
    
    .division_foot{
        padding: 10px;
        color: white;
        background-color: rgb(172, 32, 32);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-top: auto;
    }
    .division_foot h4{
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 10px;
    }
    .division_foot span{
        font-size: 15px;
        font-weight: 100;
    }
    .division_foot a{
            border: none;
            outline: none;
            cursor: pointer;
            color: rgb(226, 55, 55);
            background-color: #f5e0e0;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 700;
            margin: 0 auto;
    }
    .division_foot a:hover{
        color:red;
    }
}
 

/************************************************************************************* 3rd Media quiry  */

@media screen and (max-width:425px) {
    .container{
       width: 93%;
        margin: 10px auto;
    }
    .head_text{
        margin-top: 0px;
        font-size: 16px;
        font-weight: 900;
    }
    .head_text sub {
      color: red;
      font-size: 36px;
      font-weight:500 ;
    }
    .head_text span{
        color: red;
    }
    .division_head{
        width: 98%;
        margin-bottom: 3%;
    }
    .availability_text{
        background-color: rgb(172, 32, 32);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 10px 9px;
        color: white;
    }
    .availability_text span{
        text-align: center;
        font-size: 26px;
        display: flex;
        justify-content: center;
        font-weight: 500;
        padding-bottom: 10px;
    }
    .availability_text p{
        height: 7vh;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.6;
    }
    .main_list{
        height: 100%;
        padding: 0px 15px;
    }
    .division_list{
        font-size: 14px;
        padding: 8px 0px;
        margin-bottom: 10px;
        display: flex;
    }
    .division_list img{
        width: 14px;
        margin-right: 10px;
    }
    
    /* Body section start ************/
    .division_body{
        border-top: 2px solid rgb(214, 211, 211);
        display: flex;
        padding: 16px 15px;
        align-items: center;
        font-size: 14px;
        line-height: 1.7;
    }
    
    /* Foot Section start */
    
    .division_foot{
        padding: 10px;
        color: white;
        background-color: rgb(172, 32, 32);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-top: auto;
    }
    .division_foot h4{
        font-size: 21px;
        font-weight: 500;
        padding-bottom: 2px;
    }
    .division_foot span{
        font-size: 15px;
        font-weight: 100;
    }
    .division_foot a{
            border: none;
            outline: none;
            cursor: pointer;
            color: rgb(107, 7, 7);
            background-color: #f5e0e0;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 700;
            margin: 0 auto;
    }
    .division_foot a:hover{
        color:red;
    }
}