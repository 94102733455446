.banner {
    background-image: url('../../../themefolder/HomeHub_Hero_d.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    height: auto;
    border-radius: 0% 0% 5% 5%;
    position: relative;
}

.heading {
    gap: 15px;
    display: inline-grid;

}

.heading h1 {
    color: yellow;
}

.heading p {
    color: white;
    font-weight: bold;
}

.heading a {
    appearance: none;
    background-color: rgb(255, 255, 255);
    border: 0.0625rem solid rgb(255, 255, 255);
    border-radius: 624.938rem;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    margin: 0px 0.625rem 0.625rem 0px;
    padding: 0.75em 1.5em;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition-duration: 0.2s;
    transition-property: color;
    vertical-align: middle;
    width: 120px;
}

@media screen and (min-width: 991px) {
    .heading p {
        font-size: 32px;
    }
}

@media screen and (min-width: 768px) {
    .heading p {
        font-size: 24px;
    }
}

@media screen and (min-width: 1200px) {
    .heading h1 {
        font-size: 116px !important;
    }
}

@media screen and (min-width: 991px) {
    .heading h1 {
        font-size: 108px;
    }
}

@media screen and (min-width: 768px) {
    .heading h1 {
        font-size: 80px;
    }
}