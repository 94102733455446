.header {
  padding-top: 21px;
  padding-left: 29px;
  padding-right: 29px;
  padding-bottom: 21px;
  background: radial-gradient(circle,#cdcecf 0,#cdcecf 100%) !important;
}

.text_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_main h3 {
  font-size: 20px;
  color: #001b33;
}
.text_main a {
  text-decoration: none;
  font-size: 45px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  line-height: 38px;
}
.text_main a:hover {
  text-decoration: underline;
  color: black;
}

@media (max-width: 767px) {
  .text_main h3 {
    font-size: 14px;
  }
  .text_main a {
    font-size: 30px;
    line-height: 30px;
  }
  .text_main img {
    max-width: 200px;
  }
}
@media (max-width: 600px) {
  .text_main h3 {
    font-size: 6px;
  }
  .text_main a {
    font-size: 20px;
    line-height: 20px;
  }
  .text_main img {
    max-width: 130px;
  }
  .text_main h3 {
    font-size: 6px;
  }
  .text_main a {
    font-size: 16px;
    line-height: 16px;
  }
  .text_main img {
    max-width: 130px;
  }
}

