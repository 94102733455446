.Banner-card {
    background-color: #f3ede0;
    border-radius: 8px;
    box-shadow: var(--tpl-featured-tile_box-shadow);
    margin: var(--tpl-featured-tile_margin, 0);
    position: relative;
    transition: all 0.15s ease 0s;
    height: 30vh;
    width: 100%;

}

.section-BannerCard {
    position: absolute;
    bottom: -100px;
}

.card-heading {
    font-family: Verizon-NHG-eDS, Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    color: #f50a23;
}

.card-txt {
    font-family: var(--tpl-featured-tile__subhead_font-family, "Verizon-NHG-eDS", Helvetica, Arial, sans-serif);
    font-size: 16px;

    margin: 0 0 12px 0;
    color: black;
}

@media screen and (max-width: 991.98px) {
    .section-BannerCard {
        position: static;
    }

    .Banner-card {
        max-width: 100%;
        height: auto;
    }
}