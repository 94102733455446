.w-90 {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Faqs-text {
    font-size: 36px;
    font-weight: 700;
    font-family: "Poppins";
    color: #000;
    width: 25rem;
    line-height: 40px;
    margin-bottom: 2rem;
  }
  
  .faqs-main {
    padding-top: 80px;
  }
  
  .faqs-list {
    width: 100%;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;
    cursor: pointer;
  }
  
  .faq-question {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-toggle {
    font-size: 18px;
  }
  
  .faq-answer {
    font-size: 14px;
    font-family: "Poppins";
    color: #555;
    margin-top: 0.5rem;
  }

  .faq-answer{
    text-align: start;
    font-size: 12px;
    font-family: "Poppins";
    color: #000;
    line-height: 20px;
    width: 82%;
  }

  .info-section {
    font-family: "Poppins", sans-serif;
    color: #333;
    line-height: 1.6;
    width: 60%;
  }
  
  .info-section p {
    margin-bottom: 1.5rem;
    font-size: 12px;
    line-height: 1.5;
    text-align: start;
  }
  
  .info-section ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 1.5rem;
  }
  
  .info-section li {
    margin-bottom: 0.75rem;
    font-size: 12px;
    line-height: 1.5;
  }
  
  .info-section a {
    color: #0071e3;
    text-decoration: none;
    font-weight: 700;
  }
  
  .info-section a:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width:767px) {
    .Faqs-text {
        font-size: 20px;
        font-weight: 400;
        font-family: "Poppins";
        color: #000;
        width: 20rem;
        line-height: 20px;
        margin-bottom: 2rem;
      }

      .faq-answer{
        text-align: start;
        font-size: 12px;
        font-family: "Poppins";
        color: #000;
        line-height: 20px;
        width: 100%;
      }
    
      .info-section {
        font-family: "Poppins", sans-serif;
        color: #333;
        line-height: 1.6;
        width: 100%;
      }
  }