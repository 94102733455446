#text-div{
    width: 43%;
}

#Policies-text-main{
    width: 65%;
}

#policies-main{
    margin-top: 110px;
}

#policy-headings{
    font-size: 16px;
    font-family: "poppins";
    color: #121212BF;
    text-align: center;
    font-weight: 600;
}

p{
    font-size: 16px;
    font-family: "poppins";
    color: #121212BF;
    text-align: center;
    font-weight: 390;
    line-height: 32px;
    margin-top: 16px;
    margin-bottom: 16px;
}

a{
    font-size: 16px;
    font-family: "poppins";
    color: #121212BF;
    text-align: center;
    font-weight: 390;
}

@media screen and (max-width:749px) {
    #Policies-text-main{
        width: 90%;
    }

    #text-div{
        width: 90%;
    }
}