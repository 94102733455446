#text-div{
    width: 43%;
}

#Disclaimer-text{
    font-size: 20px;
    font-weight: 400; 
    font-family: "poppins";
    color: #121212B3;
}

#Disclaimer-text-main{
    width: 50vw;
    margin-top: 90px;
}

a{
    font-size: 20px;
    font-weight: 400;
    font-family: "poppins";
    color: #121212B3;
    margin-right: 5px;
}

@media screen and (max-width:768px) {
    #Disclaimer-text-main{
        width: 90%;
        margin-top: 90px;
    }
}