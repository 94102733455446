.header-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-one-main {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 0.5px solid gray;
}

.header-first {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
}

.first-div {
    display: flex;
    width: 8.5rem;
    justify-content: space-between;
}

.links-div {
    display: flex;
    justify-content: space-between;
    width: 45%;
}

p {
    margin: 0px;
}

.personal {
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins";
    color: #f50a23 !important;
}

.business {
    font-size: 12px;
    font-weight: 500;
    font-family: "Poppins";
    color: #000 !important;
}

.text-1 {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
}

a {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
    padding-top: 7px;
    text-decoration: none;
}

.line {
    height: 3px;
}

.search-input {
    width: 100px;
    border: none;
}

.search-input:focus {
    outline: none;
}

.search-main {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    padding: 10px 14px;
    border-radius: 20px;
}

.signIn {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    color: #000;
}

.header-two-main {
    width: 90%;
}

.logo-text {
    font-size: 30px;
    font-weight: 800;
    font-family: "Poppins";
    color: #f50a23;
}

.links-two {
    font-size: 16px;
    font-weight: 600;
    font-family: "Poppins";
    color: #000;
}

.header-two-links {
    width: 30rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.w-20 {
    width: 17rem;
}

.header-three-main {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
}

.anchor-offer {
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
    border-bottom: 1px solid #000;
}

.bg-Color1 {
    background-color: #f3ede0 !important;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.text-two {
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins";
    color: #000;
}

.text-three {
    font-size: 11.5px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
    text-align: start;
}

.bg-Color2 {
    background-color: #f3ede0 !important;
}

.w-65 {
    width: 28rem;
    align-items: end;
}

.text-address {
    font-size: 11px;
    font-family: "Poppins";
    color: #000;
    font-weight: 500;
}

.location-link {
    font-size: 11px;
    font-family: "Poppins";
    color: #000;
    font-weight: 500;
}

.input3-main {
    width: 40%;
}

.getStarted-button {
    border: 1px solid #000;
    padding: 8px 24px;
    border-radius: 24px;
    background-color: #000;
}

.buttonStarted {
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins";
    color: #ffffff;
}

.emailInput {
    height: 40px;
    border: 1px solid #000;
    border-radius: 4px;
    padding-left: 10px;
}

.emailInput:focus {
    outline: none;
}

.searchSingle{
    display: none;
}

.search-main{
    display: block;
}

@media screen and (max-width: 1025px) {
    .header-first {
        display: none;
    }

    .header-two-links {
        width: 7.2rem;
    }

    .links-two{
        display: none;
    }

    .searchSingle{
        display: block;
    }

    .search-main{
        display: none;
    }

    .w-20 {
        width: 8rem;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .links-div {
        display: flex;
        justify-content: space-between;
        width: 52%;
    }
}

@media screen and (min-width:768px) and (max-width:1025px) {
    .bg-Color2{
        padding: 0px 35px;
    }

    .links-two{
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .header-two-main {
        flex-direction: column;
        align-items: center;
    }

    .header-two-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .search-main {
        width: 100%;
        padding: 10px;
        margin-bottom: 1rem;
    }

    .w-20 {
        width: 12rem;
        display: flex;
        justify-content: space-around;
    }

    .bg-Color2 {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .w-65{
        width: 90%;
        flex-direction: column;
        align-items: start !important;
    }

    .input3-main {
        width: 90%;
    }

    .getStarted-button {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .header-two-links {
        flex-direction: column;
        align-items: start;
        margin-bottom: 0px;
    }

    .header-two-links a {
        margin-bottom: 0.5rem;
    }

    .search-main {
        flex-direction: column;
        align-items: center;
    }

    .search-main input {
        margin-bottom: 0.5rem;
    }
}
