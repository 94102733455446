.OurServicesSectionCard-Images {
    max-width: 100%;
    height: 50vh;
}

.OurServicesSection-button a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.962);
    padding: 15px 35px;
    background-color: black;
    z-index: 1;
    border-radius: var(--buttons-radius);
    box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset)) rgba(var(--color-button-text), var(--border-opacity)), 0 0 0 var(--buttons-border-width) rgba(var(--color-button), var(--alpha-button-background));
    transition: box-shadow var(--duration-short) ease;


}

.OurServicesSection-button a:hover {
    color: white;
    box-shadow: white;
}
.card-text p {
    text-align: left !important;
}