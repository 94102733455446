#text-div{
    width: 43%;
}

#Cable-TV{
    width: 100%;
    height: 1980px;
}

#main-div-cable{
    width: 80%;
    justify-content: space-between;
}

#header-text{
    font-family: "poppins";
    color: #121212;
}

#text-1{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 30px;
    line-height: 34px;
}

#text-2{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-3{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-4{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-5{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-6{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-7{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-8{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-9{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-10{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-11{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-12{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-13{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#text-14{
    font-size: 18px;
    font-weight: 390;
    font-family: "poppins";
    color: #121212B3;
    margin-top: 15px;
    line-height: 34px;
}

#main-text-div{
    margin-top: 100px;
}

#anchor{
    color: gray;
    font-size: 18px;
    font-weight: 390;
    text-decoration: none;
    border-bottom: 1px solid #121212;
    margin-left: 6px;
    margin-right: 6px;
}

@media screen and (min-width:1024px) and (max-width:1200px) {
    #Cable-TV{
        height: 2500px;
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    #Cable-TV{
        height: 3000px;
    }
}

@media screen and (max-width:767px) {
    #main-div-cable{
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    #main-text-div{
        margin-top: 80px;
    }

    #texts-div{
        margin-top: 40px;
    }

    #Cable-TV{
        height: 300px;
    }
}