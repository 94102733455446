.servicearea-parent{
    margin-top: 12%;
    margin-bottom: 12%;
}

.servicearea-main{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicearea-header{
    font-size: 48px;
    font-weight: 700;
    font-family: "Poppins";
    color: #000;
    width: 85%;
    line-height: 52px;
}

.servicearea-description{
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    color: #000;
    width: 100%;
}

.servicearea-button{
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins";
    color: #000;
    border: 1px solid #000;
    padding: 10px 28px;
    border-radius: 20px;
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .servicearea-header{
        font-size: 32px;
        font-weight: 600;
        font-family: "Poppins";
        color: #000;
        width: 85%;
        line-height: 36px;
    }
}

@media screen and (max-width:767px) {
    .servicearea-header{
        font-size: 20px;
        font-weight: 600;
        font-family: "Poppins";
        color: #000;
        width: 100%;
        line-height: 32px;
    }

    .servicearea-description{
        font-size: 14px;
        font-weight: 500;
        font-family: "Poppins";
        color: #000;
        width: 100%;
        line-height: 20px;
    }
}