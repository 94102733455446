.main{
    display: flex;
    padding-top: 40px;
}
.sec_img{
    padding-bottom: 40px;
    text-align: right;
    width: 50%;
    padding-top: 35px;
}
.sec_img img{
    width: 80%;
    margin: auto;
}
.number{
    display: flex;
    align-items: center;
}
.number a{
    display: flex;
    align-items: center;
}
.number a img{
    margin-left: -6px;
}
.number a {
    color: #000;
    font-weight: 600;
}
.sec_main_text{
    width: 50%;
    padding-left: 20px;
    padding-top: 35px;
}
.sec_main_text h2{
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    color: #333333;
    margin-right: auto;
    max-width: 572px;
}
.spectrum_mbl{
    color: #00629b;
    font-size: 24px ;
}
.tick_icon1{
    display: flex;
    padding: 6px 0;
}
.tick_icon1 p {
    font-size: 17.5px;
    color: #333333;
    margin-left: 7px;
}
.tick_icon1 img {
    margin-top: 7px;
    height: 12px;
    margin-right: 8px;
}
.phone{
    padding-left: 10px;
    color: #000000;
    font-size: 28px;
    font-weight: 700;
    margin-top: 30px;
}
.foot_text{
    font-size: 13px;
    margin-top: 10px;
    color: #333333;
}
.foot_text p{
    margin-right: auto;
    max-width: 610px;
}
@media screen and (max-width:1025px) {
    .sec_img{
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: right;
        width: 50%;
    }
    .sec_img img{
        width: 100%;
    }
    .tick_icon1 p {
        font-size: 15px;
        color: #333333;
    }
    .sec_main_text{
        width: 50%;
        padding: 38px;
    }
    .foot_text{
        font-size: 10px;
        margin-top: 10px;
        color: #333333;
    }
}
@media screen and (max-width:767px) {
    .main{
        display: flex;
        flex-direction: column;
    }
    .sec_img{
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: right;
        width: 80%;
    }
    .sec_img img{
        width: 100%;
    }
    .sec_main_text{
        width: 95.5%;
        padding: 10px;
    }
    .tick_icon1 p {
        font-size: 12px;
        color: #333333;
    }
    .foot_text{
        font-size: 8px;
        margin-top: 10px;
        color: #333333;
    }
}
@media screen and (max-width:600px){
    .sec_main_text{
    
        padding: 10px 25px;
    }
    .sec_main_text h2{
        font-size: 36px;
        font-weight: 400;
        line-height: 43px;
    }
    .foot_text{
        font-size: 10px;
       
    }
}