.PlansCard {
    position: unset;

}

.FirstPlansCard {
    border-radius: 24px;
    cursor: pointer;
    height: 490px;
    transition: all 0.25s ease 0s;
    background-color: #f3ede0;
}

.heading-rapo h6 {
    color: black;
}

.txt-rapo h6 {
    color: black;
    display: flex;
    margin: 0px;
    align-items: center;
}

.txt-rapo {
    line-height: 9px;
    display: grid;
    justify-content: end;
}

.txt-rapo span {
    color: black;
    font-weight: 500;
    font-size: 10px;
}

.span-two {
    color: green !important;
    font-weight: 500;

    font-size: 10px;
}

.red-box {
    border-radius: 15px;
    height: 84px;
    background-color: red;
    padding: 12px !important;
}

.brands-box {
    background-color: whitesmoke;
    padding: 12px !important;
}

.brands-box p {
    color: black;
    font-weight: 200;
}

.brands-box span {
    color: black;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.cardfooter {
    display: flex;
    justify-content: center;
    align-items: end;
    position: absolute;
    bottom: 2px;
}