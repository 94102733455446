.SectionThird {
    background-image: url(../../themefolder/mario-caruso-0C9VmZUqcT8-unsplash.jpg);
    max-width: 100%;
    padding: 120px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.SectionThird-info-box {

    padding: 4rem 3.5rem;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    text-align: center;
    word-wrap: break-word;
}

.SectionThird-heading-text-buttons-rapo h2 {
    font-family: var(--font-heading-family);
    font-style: var(--font-heading-style);
    font-weight: var(--font-heading-weight);
    letter-spacing: calc(var(--font-heading-scale) * .06rem);
    color: white;
    line-height: calc(1 + .3/max(1, var(--font-heading-scale)));
    word-break: break-word;
}

.SectionThird-heading-text-buttons-rapo p {
    font-size: 1.8rem;
    line-height: calc(1 + .8 / var(--font-body-scale));
    letter-spacing: .06rem;
    color: gray;
    font-weight: 500;
}

.SectionThird-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 1rem 3rem;
    cursor: pointer;
    font: inherit;
    font-size: 1.5rem;
    text-decoration: none;
    color: rgb(var(--color-button-text));
    transition: box-shadow var(--duration-short) ease;
    -webkit-appearance: none;
    appearance: none;

}
.callus-btn {
    list-style: none !important;
    text-decoration: none !important;

}