.large_text {
  font-weight: 400;
  line-height: 18px;
  font-size: 13px;
  color: #333333;
}
.headings {
  font-family: 700;
  line-height: 18px;
  font-size: 13px;
  color: #333333;
}
.Large_text p {
  margin-bottom: 40px;
}
.Large_text p a {
  text-decoration: none;
}
