.diclaimer-parent{
    margin-top: 5%;
    margin-bottom: 7%;
    width: 44rem;
}

.dislaimer-header{
    font-size: 32px;
    font-weight: 700;
    font-family: "Poppins";
    color: #000;
}

.dislaimer-description{
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
    margin-top: 24px;
}

.verizon-anchor{
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins";
    color: #000;
    margin-top: 24px;
}

@media screen and (max-width:768px) {
    .diclaimer-parent{
        margin-top: 2%;
        margin-bottom: 6%;
        width: 90%;
    }
    
    .dislaimer-header{
        font-size: 28px;
        font-weight: 600;
        font-family: "Poppins";
        color: #000;
    }
    
    .dislaimer-description{
        font-size: 12px;
        font-weight: 400;
        font-family: "Poppins";
        color: #000;
        margin-top: 24px;
    }

    .verizon-anchor{
        font-size: 12px;
        font-weight: 400;
        font-family: "Poppins";
        color: #000;
        margin-top: 24px;
    }
}