.Disclaimer-heading-txt-rapo {
    max-width: 70%;
    height: auto;
    display: grid;
    justify-content: center;
    text-align: center;
}

.Disclaimer-heading-txt-rapo h2 {
    font-size: 4rem;
    font-weight: 400;
}