.mini-heading p {
    font-size: 1rem;
    letter-spacing: .13rem;
    line-height: calc(1 + .2 / var(--font-body-scale));
    text-transform: uppercase;

}

.mini-heading h2 {
    font-size: 4rem;
    font-weight: 400;

}

.SectionFaqs-col {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: transparent;
    /* box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); */
    text-decoration: underline;
}

.accordion-button:hover {
    background-color: rgb(224, 226, 228);
}