.section-one {
    background-image: url(../../themefolder/nasa-1lfI7wkGWZ4-unsplash4.jpg);
    max-width: 100%;
    /* height: 60vh; */
    background-position: center;
    padding: 120px 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /* filter: brightness(0.7); */

}

.banner-heading,
.banner-text,
.banner-button {
    z-index: 1;
}

.banner-heading h2 {
    font-family: var(--font-heading-family);
    font-style: var(--font-heading-style);
    font-weight: var(--font-heading-weight);
    letter-spacing: calc(var(--font-heading-scale) * .06rem);
    color: white;
    line-height: calc(1 + .3/max(1, var(--font-heading-scale)));
    word-break: break-word;

}

.banner-text p {
    font-size: 1.8rem;
    line-height: calc(1 + .8 / var(--font-body-scale));
    letter-spacing: .06rem;
    color: rgb(112, 112, 112);
    font-weight: 700;

}


.section-one::after {
    content: "";
    position: absolute;
    top: 0;
    background: #000;
    opacity: 0.4;
    z-index: 0;
    width: 100%;
    height: 100%;

}


.banner-button a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.962);
    padding: 15px 35px;
    z-index: 1;
    border-radius: var(--buttons-radius);
    box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset)) rgba(var(--color-button-text), var(--border-opacity)), 0 0 0 var(--buttons-border-width) rgba(var(--color-button), var(--alpha-button-background));
    transition: box-shadow var(--duration-short) ease;


}

.banner-button a:hover {
    color: white;

    box-shadow: white;
}

.banner-heading {
    margin-top: 25%;
}