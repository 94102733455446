#email-text{
    font-size: 18px;
    color: #121212B3;
    font-family: "poppins";
    font-weight: 400;
}

#address-1{
    font-size: 18px;
    color: #121212B3;
    font-family: "poppins";
    font-weight: 400;
    margin-top: 22px;
}

#address-2{
    font-size: 18px;
    color: #121212;
    font-family: "poppins";
    font-weight: 500;
}

#address-3{
    font-size: 18px;
    color: #121212B3;
    font-family: "poppins";
    font-weight: 400;
    margin-top: 16px;
}

#phone-1{
    font-size: 18px;
    color: #121212B3;
    font-family: "poppins";
    font-weight: 400;
    margin-top: 16px;
}

#phone-2{
    font-size: 18px;
    color: #121212;
    font-family: "poppins";
    font-weight: 500;
}

#footer-main{
    border-top: 0.3px solid gray;
    border-bottom: 0.3px solid gray;
    padding-top: 36px;
    padding-bottom: 36px;
}

#footer-text-1{
    font-size: 12px;
    font-weight: 400;
    color: gray;
    margin-bottom: 14px;
}

#footer-anchor-1{
    font-size: 12px;
    font-weight: 400;
    color: gray;
    margin-left: 5px;
    text-decoration: none;
}

#footer-anchor-1:hover{
    border-bottom: 0.5px solid #121212;
}

#footer-anchor-2:hover{
    border-bottom: 0.5px solid #121212;
}

#footer-anchor-3:hover{
    border-bottom: 0.5px solid #121212;
}

#footer-anchor-4:hover{
    border-bottom: 0.5px solid #121212;
}

#footer-anchor-2{
    font-size: 12px;
    font-weight: 400;
    color: gray;
    text-decoration: none;
}

#footer-anchor-3{
    font-size: 12px;
    font-weight: 400;
    color: gray;
    text-decoration: none;
}

#footer-anchor-4{
    font-size: 12px;
    font-weight: 400;
    color: gray;
    text-decoration: none;
}

#footer-text-2{
    margin-left: 7px;
}

#footer-text-3{
    margin-left: 7px;
}

#footer-text-4{
    margin-left: 7px;
}

@media screen and (max-width:767px) {
    #footer-info-area{
        padding-left: 40px;
    }

    #footer-parent{
        justify-content: start;
    }

    #logo-div{
        justify-content: center;
        margin-top: 30px;
    }

    #copyright-div{
        justify-content: center;
    }
}

@media screen and (min-width:768px) {
    #footer-info-area{
        padding-left: 40px;
    }

    #footer-parent{
        justify-content: end;
    }

    #logo-div{
        justify-content: end;
    }

    #copyright-div{
        justify-content: start;
    }
}
