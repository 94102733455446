.top-header-text p {
    padding: 1rem 0;
    letter-spacing: .1rem;
}

.nav-link {
    color: gray;
    display: flex;
    align-items: center;
    line-height: calc(1 + 0.3 / var(--font-body-scale));
    padding: 1.2rem;
    font-size: 1.4rem;
    font-family: inherit
}

.nav-link:focus,
.nav-link:hover {
    color: rgb(var(--color-foreground));
    text-decoration: underline;
    text-underline-offset: 0.3rem;
}

.nav-icons {
    height: 2.4rem;
    width: 2.4rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.nav-icons a {
    text-decoration: none;
    width: 100%;
    color: black;
    height: auto;
}