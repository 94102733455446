   .cardsSection-main {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cards-section {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 20px;
}

.bgColor3{
    background-color: #f3ede0 !important;
    padding: 80px 0px;
}

.card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
    transition: all 0.3s ease; /* Add transition for smooth effect */
}

.card:hover {
    transform: scale(1.05); /* Scale the card up on hover */
}

.card-image {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 16px;
}

.card-title {
    font-size: 1.4rem;
    font-weight: bold;
    font-family: "Poppins";
    margin: 0;
    margin-bottom: 8px;
}

.card-subtitle {
    color: #666;
    margin: 0;
    font-size: 12px;
    text-align: start;
    margin-bottom: 16px;
}

.card-link {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

.card-link:hover {
    text-decoration: underline;
}

.cardsSection-title{
    font-size: 40px;
    font-weight: 700;
    font-family: "Poppins";
    color: #000;
}

@media screen and (max-width:1025px) {
    .cards-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;
        margin-top: 40px;
    }

    .bgColor3{
        background-color: #f3ede0 !important;
        padding: 50px 0px;
    }

    .cardsSection-title{
        font-size: 32px;
        font-weight: 700;
        font-family: "Poppins";
        color: #000;
    }
}