.main_wrapper2{
    max-width: 1390px;
    margin: 10px auto;
   border-top: 1px solid gray;
   padding: 20px 0px;
}
.container{
    max-width: 1390px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
/* Text css */

.wrapper2_text{
    margin: 10px;
    padding: 10px;
    width: 50%;
}
.wrapper2_text h3{
    font-size: 25px;
    font-weight: 400;
    padding-bottom: 19px ;
}
.wrapper2_text span{
    color: red;
    font-size: 30px;
    font-weight: 500;
}
.wrapper2_text p{
    font-size: 16px;
    font-weight: 100;
    line-height: 1.6;
}
.wrapper2_text p span{
    color: rgb(25, 3, 228);
    font-size: 16px;
}
.wrapper2_text ul{
    padding: 1px 16px;
    margin-top: 0px;
}
.wrapper2_text li{
    list-style-type: disc;
    font-size: 16px;
    font-weight: 100;
    padding: 9px;
    line-height: 1.6;

}

/* Image css */
.wrapper2_img{
    width: 50%;
    display: flex;
    justify-content: center;
}
.wrapper2_img img{
    max-width: 500px;
}

/* ****************************************** 1st Media Quiery */

@media screen and (max-width:1024px) {
    .main_wrapper2{
        margin-top: 0%;
     }
     .container{
         max-width: 1390px;
         padding: 0 10px;
         margin: 0 auto;
         display: flex;
         justify-content: space-between;
     }
     /* Text css */
     
     .wrapper2_text{
         margin: 10px;
         padding: 5px;
         width: 50%;
     }
     .wrapper2_text h3{
         font-size: 22px;
         font-weight: 400;
         padding-bottom: 10px ;
     }
     .wrapper2_text span{
         color: red;
         font-size: 26px;
         font-weight: 500;
     }
     .wrapper2_text p{
         font-size: 12.5px;
         font-weight: 100;
         line-height: 19px;
     }
     .wrapper2_text p span{
         color: rgb(25, 3, 228);
         font-size: 12.5px;
     }
     .wrapper2_text ul{
         padding: 1px 16px;
         margin-top: 0px;
     }
     .wrapper2_text li{
         list-style-type: disc;
         font-size: 12px;
         font-weight: 100;
         padding: 6px;
     }
     
     /* Image css */
     .wrapper2_img{
         width: 50%;
         display: flex;
         justify-content: center;
         margin: 0px;
     }
     .wrapper2_img img{
         max-width: 400px;
}
}



/* ****************************************** 2nd Media Quiery */

@media screen and (max-width:768px) {
    .main_wrapper2{
        margin-top: 0%;
     }
     .container{
         max-width: 1250px;
         margin: 0 auto;
         display: flex;
         flex-direction: column-reverse;
         justify-content: space-between;
     }
     /* Text css */
     
     .wrapper2_text{
         margin: 10px;
         padding: 10px;
         width: 100%;
     }
     .wrapper2_text h3{
         font-size: 22px;
         font-weight: 400;
         padding-bottom: 10px ;
     }
     .wrapper2_text span{
         color: red;
         font-size: 26px;
         font-weight: 500;
     }
     .wrapper2_text p{
         font-size: 12.5px;
         font-weight: 100;
         line-height: 19px;
     }
     .wrapper2_text p span{
         color: rgb(25, 3, 228);
         font-size: 12.5px;
     }
     .wrapper2_text ul{
         padding: 1px 16px;
         margin-top: 0px;
     }
     .wrapper2_text li{
         list-style-type: disc;
         font-size: 12px;
         font-weight: 100;
         padding: 6px;
     }
     
     /* Image css */
     .wrapper2_img{
         width: 100%;
         display: flex;
         justify-content: center;
         margin: 10px;
     }
     .wrapper2_img img{
         max-width: 400px;
}
}



/* ****************************************** 3rd Media Quiery */

@media screen and (max-width:600px) {
    .main_wrapper2{
        margin-top: 0%;
        max-width: 1390px;
     }
     .container{
         max-width: 1390px;
         padding: 0 10px;
         margin: 0 auto;
         display: flex;
         flex-direction: column-reverse;
         justify-content: space-between;
     }
     /* Text css */
     
     .wrapper2_text{
         max-width: 1390px;
     }
     .wrapper2_text h3{
         font-size: 20px;
         font-weight: 400;
         padding-bottom: 10px;
     }
     .wrapper2_text span{
         color: red;
         font-size: 24px;
         font-weight: 500;
     }
     .wrapper2_text p{
         font-size: 14px;
         font-weight: 100;
         line-height: 1.9;
     }
     .wrapper2_text p span{
         color: rgb(25, 3, 228);
         font-size: 14px;
     }
     .wrapper2_text ul{
         padding: 1px 16px;
         margin-top: 0px;
     }
     .wrapper2_text li{
         list-style-type: disc;
         font-size: 14px;
         font-weight: 100;
         padding: 6px;
     }
     
     /* Image css */
     .wrapper2_img{
         width: 100%;
         display: flex;
         justify-content: center;
     }
     .wrapper2_img img{
         max-width: 280px;
}
}