/* Styles for responsive sizing and positioning */
@media only screen and (max-width: 428px) {
  .heading {
    font-size: 28px !important;
  }
  .popupFooter {
    font-size: 1.1rem !important;
    height: 10vh !important;
  }
  .phoneNumber {
    font-weight: bold;
    background-color: #003057;
    color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    padding: 18px !important;
    font-size: 16px !important;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 768px) {
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fff !important;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    z-index: 999; /* Ensure popup is above other content */
  }
  .overlay {
    cursor: pointer;
  }
  .popupFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: larger;
    width: 100%;
    background-color: #003057;
    height: 12vh;
    color: #f0f0f0 !important;
    font-size: 24px;
  }
  .popupHeader {
    /* visibility: hidden; */

    min-width: 100%;
    max-width: 100%;
    background-color: #003057;
    height: 1vh;
    color: #f0f0f0 !important;
    border-radius: 0 0 2px 2px;
  }

  .popupContent {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .logo {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 170px;
    max-width: 100%;
    height: 60px;
    margin-bottom: 10px !important;
  }

  .heading {
    font-size: 35px; /* Adjust heading size as needed */
    margin-bottom: 20px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #050505cb;
    font-weight: 750;
    padding-top: 20px !important;
  }

  .phoneNumber {
    font-weight: bold;
    color: #f0f0f0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw !important;
    padding: 20px !important;
    font-size: 24px !important;
    border-radius: 6px !important;
  }
}

@media only screen and (min-width: 768px) {
  .popup {
    position: fixed;
    top: 5vh;
    left: 5vw;
    width: 30vw;
    height: 30vw;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between !important;
    gap: 10px;
    z-index: 999;
  }
  .popupFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: medium !important;
    width: 100%;
    background-color: #003057;
    height: 8vh;
    color: #f0f0f0 !important;
    font-size: 20px;
  }
  .popupHeader {
    /* visibility: hidden; */
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    font-size: larger;
    padding-left: 10%;
    max-width: 100%;
    background-color: #003057;
    height: 1vh;
    color: #f0f0f0 !important;
    font-size: 24px;
  }

  .popupContent {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }

  .logo {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 130px !important;
    height: 60px !important;
    margin-bottom: 10px !important;
  }

  .heading {
    font-size: 22px; /* Adjust heading size as needed */
    margin-bottom: 20px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }

  .phoneNumber {
    font-weight: bold;
    color: #f0f0f0 !important;
    display: flex;
    font-size: medium !important;
    justify-content: center;
    align-items: center;
    /* width: 80vw; */
    min-width: 80% !important;
    padding: 10px !important;
    font-size: 20px !important;
  }
}
